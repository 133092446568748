import React from 'react';
import { Link } from 'react-router-dom';

const PageHead = ({ title }) => {
	return (
		<div className='shadow  py-3'>
			<div className='container'>
				<div className='text-xl'>
					<Link to='/' className='hover:text-primary text-gray-400'>
						Home
					</Link>
					{' > '}
					<span className='text-primary'>{title}</span>
				</div>
			</div>
		</div>
	);
};

export default PageHead;
