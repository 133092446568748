import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Components/Common/Footer';
import Navbar from '../../Components/Common/Navbar';

const Morrison = () => {
    const [catData, setCatData] = React.useState([]);

    React.useEffect(() => {
        fetch('https://nazadmin.icicle.dev/catData')
            .then((res) => res.json())
            .then(({data}) => {
                const sb = data.filter((item, i) => i < 12);
                setCatData(sb);
            });
    }, []);

    return (
        <>
            <Navbar transparent={false} />
            <div className="container  py-8">
                <h2 className="text-3xl text-center">
                    Capital Textile Machinery
                </h2>
                <div className="flex flex-wrap mt-8 justify-center gap-6">
                    {
                        catData.map((cat) => cat?.subcategories.length ? <div to={`/products/${cat.id}`} className='px-6 py-2 bg-gray-300 rounded text-gray-900 flex items-center gap-2 cursor-pointer relative group'>
                            {cat.name} <svg
                                className='fill-current h-4 w-4 transition duration-150 ease-in-out'
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 20 20'>
                                <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                            </svg>
                            <ul className='bg-gray-300 text-gray-900 rounded overflow-hidden absolute top-10 left-0 lg:right-0 z-50 transition duration-150 ease-in-out origin-top-left shadow-2xl scale-0 group-hover:scale-100 min-w-32'>
									{
										cat.subcategories.map((subcat) => <li key={subcat.id} className='px-4 py-1.5 hover:bg-gradient-to-b from-primary to-[#118ab7] hover:text-white'>
											<Link to={`/products/sub/${subcat.id}`}>{subcat.name}</Link>
										</li>)

									}
								</ul>
                        </div> : <Link to={`/products/${cat.id}`} className='px-6 py-2 bg-gray-300 rounded text-gray-900'>
                            {cat.name}
                        </Link>)
                    }
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Morrison;