import React from 'react';
import Accordion from '../Components/Common/Accordion';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const Media = () => {
	return (
		<div>
			<Navbar transparent={false} />
			<PageHead title='Sclavos SA ' />
			<div className='container py-12'>
				<div className='grid grid-cols-1 lg:grid-cols-2'>
					<div>
						<Accordion title='Press Release' content={<A1 />} />
						<Accordion title='In Media' content={<A2 />} />
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

const A1 = () => (
	<div>
		<a href='#.' className='font-bold text-xs mb-3 block'>
			{' '}
			VIEW ALL{' '}
		</a>
		<div className='flex my-4'>
			<img src='/img/s1.png' alt='' />
			<div className='p-2'>
				<a href='#.' className='hover:text-primary'>
					aamra resources limited Wins “Best Partner Award for 2021 –
					Consumables & Parts” at Bangkok by Lectra
				</a>
				<p className='text-gray-400'>
					aamra resources limited (ARL) has been awarded by Lectra SA, France as
					“Best Partner Awar...
				</p>
			</div>
		</div>
		<hr />
		<div className='p-2 my-4'>
			<a href='#.' className='hover:text-primary'>
				Lectra completes the acquisition of Gerber Technology
			</a>
			<p className='text-gray-400'>
				Lectra finalizes today the acquisition of all outstanding shares of
				Gerber Technology, on a cas...
			</p>
		</div>
		<hr />
		<div className='flex my-4'>
			<img src='/img/s2.jpg' alt='' />
			<div className='p-2'>
				<a href='#.' className='hover:text-primary'>
					aamra, Lectra and BUBT agreement to provide complementary support for
					BUBT students on Pattern and Marker software and Training
				</a>
				<p className='text-gray-400'>
					In the era of Industry 4.0 aamra resources limited is trying to
					inspire the Apparel and Textile...
				</p>
			</div>
		</div>
		<hr />
		<div className='flex my-4'>
			<img src='/img/s3.jpg' alt='' />
			<div className='p-2'>
				<a href='#.' className='hover:text-primary'>
					aamra and Lectra organized ‘Collaborative Product Development’ seminar
				</a>
				<p className='text-gray-400'>
					The future is knocking on the door and it is very exciting, on 20th
					and 21st November 2019 aamr...
				</p>
			</div>
		</div>
		<hr />
		<div className='flex my-4'>
			<img src='/img/s4.jpg' alt='' />
			<div className='p-2'>
				<a href='#.' className='hover:text-primary'>
					aamra, Lectra and BUBT agreement to provide complementary support for
					BUBT students on Pattern and Marker software and Training
				</a>
				<p className='text-gray-400'>
					In the era of Industry 4.0 aamra resources limited is trying to
					inspire the Apparel and Textile...
				</p>
			</div>
		</div>
	</div>
);

const A2 = () => (
	<p>
		aamra participates in the 12th Dhaka International Textile & Garment
		Machinery Exhibition, 2015.
		<br />
		<br />
		'aamra' attends Inaugural Bangladesh Investment Summit - Unlocking
		opportunities for Asian investors
		<br />
		<br />
		Share price of aamra technologies at highest peak
	</p>
);

export default Media;
