import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AboutUs from './Pages/AboutUs';
import BusinessPartner from './Pages/BusinessPartner';
import Career from './Pages/Career';
import Contact from './Pages/Contact';
import Dyes from './Pages/Dies';
import FactSheet from './Pages/FactSheet';
import Home from './Pages/Home';
import Management from './Pages/Management';
import Media from './Pages/Media';
import NazEngineering from './Pages/NazEngineering';
import Organogram from './Pages/Organogram';
import Morrison from './Pages/Product/Morrison';
import ProductSingle from './Pages/Product/ProductSingle';
import Recognitions from './Pages/Recognitions';
import './Responsive.css';

function App() {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Home />} />

				<Route path='company/about' element={<AboutUs />} />
				<Route path='company/management' element={<Management />} />
				<Route path='company/organogram' element={<Organogram />} />
				<Route path='company/fact-sheet' element={<FactSheet />} />
				<Route path='company/partners' element={<BusinessPartner />} />

				<Route path='products/:id' element={<ProductSingle />} />
				<Route path='products/sub/:subId' element={<ProductSingle />} />

				<Route path='morrison' element={<Morrison />} />
				<Route path='dyes_chemical' element={<Dyes />} />
				<Route path='naz_engineering' element={<NazEngineering />} />

				<Route path='media' element={<Media />} />
				{/* <Route path='recognitions' element={<Recognitions />} /> */}
				<Route path='career' element={<Career />} />
				<Route path='contact' element={<Contact />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
