import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

const AboutSection = () => {
	return (
		<div className='bg-[#E5E5E5]'>
			<div className='container py-16'>
				<div className='flex flex-col lg:flex-row gap-8'>
					<div data-aos-duration="400"
						data-aos="zoom-in-up" className='lg:w-4/12 shadow-xl p-4 bg-white rounded'>
						<div className='flex justify-between uppercase items-center mb-4'>
							<span className='text-xl font-semibold text-primary'>
								Latest Blog
							</span>
							<a
								href='/'
								className='text-xs font-bold text-black hover:opacity-75'>
								View All
							</a>
						</div>
						<div className='flex gap-2 mb-3'>
							<div>
								<div className='w-14 h-14 bg-cover bg-center border-2 border-transparent hover:border-primary' style={{ backgroundImage: `url("/img/c1.jpg")` }}></div>
							</div>
							{/* <p>
								<img src='/img/blog1.jpg' alt='' className='w-72' />
							</p> */}
							<div>
								<a href='#.' className='font-bold text-sm'>
									PEAK WASHER PROVES IT’S MERIT!
								</a>
								<p className='text-gray-400 text-xs'>
									PEAK WASHER PROVES IT’S MERIT! The first major innovation in
									indigo rope washing, the Peak Washer introduced...
								</p>
							</div>
						</div>
						<div className='flex gap-2'>
							<div>
								<div className='w-14 h-14 bg-cover bg-center border-2 border-transparent hover:border-primary' style={{ backgroundImage: `url("/img/c1.jpg")` }}></div>
							</div>
							{/* <p>
								<img src='/img/blog1.jpg' alt='' className='w-72' />
							</p> */}
							<div>
								<a href='#.' className='font-bold text-sm'>
									PEAK WASHER PROVES IT’S MERIT!
								</a>
								<p className='text-gray-400 text-xs'>
									PEAK WASHER PROVES IT’S MERIT! The first major innovation in
									indigo rope washing, the Peak Washer introduced...
								</p>
							</div>
						</div>
					</div>
					<div data-aos-duration="600"
						data-aos="zoom-in-left" className='lg:w-8/12 '>
						<Tabs className="shadow-xl react-tabs rounded overflow-hidden h-full">
							<TabList className='react-tabs__tab-list w-full '>
								<Tab>
									<div className='flex gap-2 items-center justify-between'>
										<p>About US</p>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											strokeWidth={1.5}
											stroke='currentColor'
											className='w-6 h-6'>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z'
											/>
										</svg>
									</div>
								</Tab>
								<Tab>
									<div className='flex gap-2 items-center justify-between'>
										<p>Management</p>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 24 24'
											fill='currentColor'
											className='w-6 h-6'>
											<path
												fillRule='evenodd'
												d='M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z'
												clipRule='evenodd'
											/>
										</svg>
									</div>
								</Tab>
								<Tab>
									<div className='flex gap-2 items-center justify-between'>
										<p>Fact Sheet</p>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											fill='none'
											viewBox='0 0 24 24'
											strokeWidth={1.5}
											stroke='currentColor'
											className='w-6 h-6'>
											<path
												strokeLinecap='round'
												strokeLinejoin='round'
												d='M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122'
											/>
										</svg>
									</div>
								</Tab>
							</TabList>

							<TabPanel>
								<div className='panel-content'>
									<h2 className='text-primary text-xl mb-4'>
										Excellence & Honesty
									</h2>
									<p>
										Naz corporation is working with some of the leading textile
										machinery manufacturers from all over the world. Over the
										years, naz corporation continued to grow, partnering with
										companies that offer leading edge technology and are at the
										forefront of development. To this day, naz corporation has
										maintained it’s philosophy of offering its customers the
										best in terms of machinery, auxiliaries,dyes & chemicals and
										after sales service.
									</p>
								</div>
							</TabPanel>
							<TabPanel>
								<div className='panel-content'>
									<h2 className='text-primary text-xl mb-4'>Management</h2>
									<p>
										Syed Faruque Ahmed is the current Chairman of aamra
										resources limited as well as aamra Companies (formerly known
										as The Texas Group Bangladesh) and is one of its founder
										shareholders.
									</p>
								</div>
							</TabPanel>
							<TabPanel>
								<div className='panel-content'>
									<h2 className='text-primary text-xl mb-4'>Fact Sheet</h2>
									<p>
										aamra resource is one of the leading Indenting houses in
										Bangladesh and plays an important role in the field of
										apparel and textiles sector. aamra resources holds the
										patents, distribution and marketing rights of a number of
										world renowned high technology driven products and are
										dedicated to the highest quality and personalized service
										and integrity
									</p>
								</div>
							</TabPanel>
						</Tabs>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
