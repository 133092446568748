import React from 'react';
import { Link } from 'react-router-dom';
import DropDownLink from './DropDownLink';

const MegaDropdownMenu = ({ text, children }) => {
	const [catData, setCatData] = React.useState([]);

	React.useEffect(() => {
		fetch('https://nazadmin.icicle.dev/catData')
			.then((res) => res.json())
			.then((data) => {
				setCatData(data.data);
			});
	}, []);

	return (
		<div id='dropdown'>
			<div className='group inline-block uppercase'>
				<button className='outline-none focus:outline-none px-3 py-1 rounded-sm flex items-center min-w-32'>
					<span className='pr-1 flex-1'>{text}</span>
					<span>
						<svg
							className='fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out'
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 20 20'>
							<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
						</svg>
					</span>
				</button>
				<ul className='bg-[#118ab7] rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 lg:left-32 left-[10%] -translate-x-[50%] ease-in-out origin-top min-w-32 capitalize'>
					{
						catData.map((cat) =>
							(cat.subcategories.length > 0) ? <li key={cat.id} className='rounded-sm relative px-6 py-1.5 hover:bg-gradient-to-b from-primary to-[#118ab7]'>
								<button className='w-full text-left flex items-center outline-none focus:outline-none'>
									<span className='pr-1 flex-1'>{cat.name}</span>
									<span className='mr-auto'>
										<svg
											className='fill-current h-4 w-4 transition duration-150 ease-in-out'
											xmlns='http://www.w3.org/2000/svg'
											viewBox='0 0 20 20'>
											<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
										</svg>
									</span>
								</button>
								<ul className='bg-[#118ab7] rounded-sm absolute top-0 left-0 lg:right-0 z-50 transition duration-150 ease-in-out origin-top-left shadow-2xl min-w-32'>
									{
										cat.subcategories.map((subcat) => <li key={subcat.id} className='px-4 py-1.5 hover:bg-gradient-to-b from-primary to-[#118ab7]'>
											<Link to={`/products/sub/${subcat.id}`}>{subcat.name}</Link>
										</li>)

									}
								</ul>
							</li> : <DropDownLink key={cat.id} link={`/products/${cat.id}`} text={cat.name} />
						)
					}
				</ul>
			</div>
		</div>
	);
};

export default MegaDropdownMenu;
