import React from 'react';

function DropDownMenu({children}) {
	return (
        <ul className='min-w-max group-hover:block hidden bg-[#118ab7] absolute top-8 text-white z-10'>
            {children}
        </ul>
	);
}

export default DropDownMenu;
