import React, { useRef, useState } from 'react';

const Accordion = ({ title, content }) => {
	const [isOpened, setOpened] = useState(false);
	const [height, setHeight] = useState('0px');
	const contentElement = useRef(null);

	const HandleOpening = () => {
		setOpened(!isOpened);
		setHeight(!isOpened ? `${contentElement.current.scrollHeight}px` : '0px');
	};
	return (
		<div onClick={HandleOpening} className='border border-gray-200'>
			<div className={'bg-white p-4 flex justify-between text-gray-800'}>
				<h4 className='font-semibold'>{title}</h4>
				{isOpened ? <ChevronOpen /> : <ChevronClosed />}
			</div>
			<div
				ref={contentElement}
				style={{ height: height }}
				className='bg-gray-100 overflow-hidden transition-all duration-200'>
				<div className='p-4'>{content}</div>
			</div>
		</div>
	);
};

const ChevronClosed = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
			strokeWidth={1.5}
			stroke='currentColor'
			className='w-6 h-6'>
			<path
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M19.5 8.25l-7.5 7.5-7.5-7.5'
			/>
		</svg>
	);
};
const ChevronOpen = () => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
			strokeWidth={1.5}
			stroke='currentColor'
			className='w-6 h-6'>
			<path
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M4.5 15.75l7.5-7.5 7.5 7.5'
			/>
		</svg>
	);
};

export default Accordion;
