import React, { useState } from 'react';
import { Twirl as Hamburger } from 'hamburger-react';

import { Link, useLocation } from 'react-router-dom';
import DropDownButton from './Navbar/Dropdown';
import DropDownMenu from './Navbar/DropDownMenu';
import DropDownLink from './Navbar/DropDownLink';
import MegaDropdownMenu from './Navbar/MegaDropdownMenu';

const Navbar = ({ transparent = true }) => {
	const location = useLocation();
	const [navOpen, setNavOpen] = useState(false);
	const checkPath = path => {
		const newPath = location.pathname.replace('/', '');
		if (!newPath) return 'empty';
		return !newPath.includes(path);
	};
	return (
		<>
			<div
				className={`p-3 bg-gradient-to-b from-primary to-[#118ab7]  ${
					checkPath() === 'empty' ? 'lg:bg-opacity-75' : ''
				} fixed top-0 z-10 w-full border-0 border-t-4 border-primary`}>
				<div className='container'>
					<div className='flex justify-between items-center'>
						<Link to='/'>
							<img src='/logo-white.png' className='w-24' alt='' />
						</Link>
						<div className='lg:hidden'>
							<Hamburger
								toggled={navOpen}
								toggle={setNavOpen}
								direction='right'
								rounded
								size={26}
								color='#fff'
								easing='ease-in'
							/>
						</div>
						<ul
							className={`lg:relative absolute top-16 ${
								navOpen ? 'right-0' : '-right-[100%]'
							} z-10 lg:backdrop-blur-none py-6 lg:py-0 bg-gray-900 lg:bg-transparent lg:top-0 lg:right-0 flex flex-col lg:flex-row w-full lg:w-auto items-center gap-4 lg:gap-0 text-sm font-bold uppercase text-white`}>
							<li>
								<Link
									to='/'
									className={`py-2 px-1 lg:px-3 border-b border-transparent ${
										checkPath() === 'empty' && 'bg-white text-gray-900'
									}`}>
									Home
								</Link>
							</li>
							<DropDownButton text='Company' active={!checkPath('company')}>
								<DropDownMenu>
									<DropDownLink link='/company/about' text='About Us' />
									<hr className='opacity-25' />
									{/* <DropDownLink link='/company/management' text='Management' />
									<hr className='opacity-25' /> */}
									{/* <DropDownLink link='/company/organogram' text='Organogram' />
									<hr className='opacity-25' /> */}
									<DropDownLink link='/company/fact-sheet' text='Fact sheet' />
									<hr className='opacity-25' />
									<DropDownLink
										link='/company/partners'
										text='Business Partners'
									/>
								</DropDownMenu>
							</DropDownButton>
							
							{/* <!-- component --> */}
							<MegaDropdownMenu text="Products" />

							<li>
								<Link
									to='/media'
									className={`py-2 px-1 lg:px-3 hover:bg-white hover:text-gray-900 ${
										!checkPath('media') && 'bg-white text-gray-900'
									}`}>
									Media
								</Link>
							</li>
							{/* <li>
								<Link
									to='/recognitions'
									className={`py-2 px-1 lg:px-3 hover:bg-white hover:text-gray-900 ${
										!checkPath('recognitions') && 'bg-white text-gray-900'
									}`}>
									Recognitions
								</Link>
							</li> */}
							<li>
								<Link
									to='/career'
									className={`py-2 px-1 lg:px-3 hover:bg-white hover:text-gray-900 ${
										!checkPath('career') && 'bg-white text-gray-900'
									}`}>
									Career
								</Link>
							</li>
							<li>
								<Link
									to='/contact'
									className={`py-2 px-1 lg:px-3 hover:bg-white hover:text-gray-900 ${
										!checkPath('contact') && 'bg-white text-gray-900'
									}`}>
									Contact
								</Link>
							</li>
							<li className='py-2 px-1 lg:px-3'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									fill='none'
									viewBox='0 0 24 24'
									strokeWidth={4}
									stroke='currentColor'
									className='w-4 h-4 cursor-pointer '>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z'
									/>
								</svg>
							</li>
						</ul>
					</div>
				</div>
			</div>

			{/* checkPath() === 'empty' */}
			<div className={` ${'block'} p-10`}></div>
		</>
	);
};

export default Navbar;
