import React from 'react';

const Footer = () => {
	return (
		<div className='bg-gradient-to-t from-primary to-[#118ab7] text-white pt-10'>
			<div className='container p-4'>
				<div className='grid grid-cols-1 lg:grid-cols-1 text-center gap-8'>
					{/* <div>
						<p className='text-xl'>Related Links</p>
						<ul className='text-sm ml-6'>
							<li className='py-1 border-0 border-dotted border-b'>
								<a href='#.'> About Us</a>
							</li>
							<li className='py-1  border-0 border-dotted border-b'>
								<a href='#.'> Management</a>
							</li>
							<li className='py-1  border-0 border-dotted border-b'>
								<a href='#.'> Organogram</a>
							</li>
							<li className='py-1  border-0 border-dotted border-b'>
								<a href='#.'> Career</a>
							</li>
						</ul>
					</div>
					<div>
						<p className='text-xl'>Employees</p>
						<ul className='text-sm ml-6'>
							<li className='py-1  border-0 border-dotted border-b'>
								<a href='#.'>EMS</a>
							</li>
							<li className='py-1 border-0 border-dotted border-b'>
								<a href='#.'>Webmail</a>
							</li>
						</ul>
					</div> */}
					<div>
						<p className='text-xl mb-3'>Contact Us</p>
						<ul className='text-sm'>
							<li className=''>House - 06, Suit: D5,</li>
							<li className=''>Road - 33, Gulshan - 1</li>
							<li className=''>Dhaka - 1212, Bangladesh. </li>
							<li className=''>Hot Line :  +8801701443454. </li>
							<li className=''>Email : rumon22@gmail.com, info@nazcorporation.com </li>
						</ul>
					</div>

					{/* <div>
						<iframe
							width='100%'
							height='100%'
							src='https://www.youtube.com/embed/YwRbyTCqOQY'
							title='Wool Production and Processing'
							frameborder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowfullscreen></iframe>
					</div>
					<div>
						<iframe
							width='100%'
							height='100%'
							src='https://www.youtube.com/embed/-vFqJtzV40E'
							title='Birkeland Wool Carding Machine - May 19, 2014'
							frameborder='0'
							allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
							allowfullscreen></iframe>
					</div> */}
				</div>

				<hr className='my-3 opacity-50' />

				<div className='flex justify-between flex-col lg:flex-row items-center gap-3 py-2'>
					<p>
						Powered BY
						<img src='/logo-white.png' alt='' className='inline h-6 pl-2' />
					</p>
					<p>
						Developed by - <a className='font-semibold' href="https://iciclecorporation.com">
							ICICLE Corporation
						</a>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;
