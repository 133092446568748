import React from 'react';
import Header from '../Components/Home/Header';
import Event from '../Components/Home/Event';
import AboutSection from '../Components/Home/AboutSection';
import Partners from '../Components/Home/Partners';
import Footer from '../Components/Common/Footer';

const Home = () => {
	return (
		<>
			<Header />
			<Event />
            <AboutSection />
            <Partners />
            <Footer />
		</>
	);
};

export default Home;
