import React from 'react';

export default function DropDownButton({ active, text, children }) {
	return (
		<li
			className={`group relative py-2 px-1 ${
				active && 'bg-white text-gray-900'
			} lg:px-3`}>
			<span className={`flex items-center gap-3`}>
				{text}
				<span>
					<svg
						className='fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out'
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 20 20'>
						<path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
					</svg>
				</span>
			</span>
			{children}
		</li>
	);
}
