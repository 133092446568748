import React from 'react';
import { Link } from 'react-router-dom';

const Event = () => {
	return (
		<div className='bg-[#E5E5E5]'>
			<div className='container py-6'>
				<h2 className='text-3xl text-center uppercase font-bold text-gray-800'>
					Catagories
				</h2>
				{/* <p className='text-gray-500 text-center'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure, ipsam.</p> */}
				<hr className='w-full' />
				<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 uppercase mt-4'>
					<Link to="/morrison" data-aos-duration="300"
						data-aos="zoom-in-right">
						<div className='relative group border-transparent border-4 rounded hover:border-primary '>
							<div
								className='h-[200px] w-full bg-cover bg-center border-2 border-primary'
								style={{ backgroundImage: 'url("/img/c1.jpg")' }}
							/>
							<button className='bg-gradient-to-r from-primary to-[#118ab7] absolute bottom-5 py-2 px-4 text-white -ml-4 group-hover:ml-3 transition-all duration-300'>
								More +
							</button>
						</div>
						<h3 className='text-lg px-3 text-primary mt-2 font-semibold text-center '>
							Capital Textile Machinery 
						</h3>
					</Link>
					<Link to="/dyes_chemical" data-aos-duration="600"
						data-aos="zoom-in-right">
						<div className='relative group  border-transparent border-4 hover:border-primary rounded'>
							<div
								className='h-[200px] w-full bg-cover bg-center border-2 border-primary'
								style={{ backgroundImage: 'url("/img/c2.jpg")' }}
							/>
							<button className='bg-gradient-to-r from-primary to-[#118ab7] absolute bottom-5 py-2 px-4 text-white -ml-4 group-hover:ml-3  transition-all duration-300'>
								More +
							</button>
						</div>
						<h3 className='text-lg px-3 text-primary mt-2 font-semibold text-center '>
							Dyes and Chemicals
						</h3>
					</Link>
					
					<Link to='/naz_engineering' data-aos-duration="900"
						data-aos="zoom-in-right">
						<div className='relative group  border-transparent border-4 hover:border-primary rounded'>
							<div
								className='h-[200px] w-full border-2 border-primary bg-cover bg-center'
								style={{ backgroundImage: 'url("/img/c4.jpg")' }}
							/>
							<button className='bg-gradient-to-r from-primary to-[#118ab7] absolute bottom-5 py-2 px-4 text-white -ml-4 group-hover:ml-3  transition-all duration-300'>
								More +
							</button>
						</div>
						<h3 className='text-lg px-3 text-primary mt-2 font-semibold text-center'>
							Naz Engineering
						</h3>
					</Link>

					<div data-aos-duration="1200"
						data-aos="zoom-in-right">
						<div className='relative group borer border-transparent border-4 hover:border-primary rounded'>
							<div
								className='h-[200px] w-full border-2 border-primary bg-cover bg-center'
								style={{ backgroundImage: 'url("/img/agro.jpg")' }}
							/>
							<button className='bg-gradient-to-r from-primary to-[#118ab7] absolute bottom-5 py-2 px-4 text-white -ml-4 group-hover:ml-3  transition-all duration-300'>
								More +
							</button>
						</div>
						<h3 className='text-lg px-3 text-primary mt-2 font-semibold text-center '>
							Naz Agro and Cattle Farm 
						</h3>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Event;
