import React from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';

const NazEngineering = () => {
	return (
		<div>
			<Navbar transparent={false} />

            <img src="/img/naz-eng/1.jpg" className='w-full' alt="" />
            <img src="/img/naz-eng/2.jpg" className='w-full' alt="" />
            <img src="/img/naz-eng/3.jpg" className='w-full' alt="" />
            <img src="/img/naz-eng/4.jpg" className='w-full' alt="" />
			

			<Footer />
		</div>
	);
};

export default NazEngineering;
