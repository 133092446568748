import React from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const AboutUs = () => {
	return (
		<>
			<Navbar transparent={false} />
			<PageHead title='About Us' />
			<div className='bg-[#F5F5F5] py-8'>
				<div className='container'>
					<p className='mb-8'>
						“Naz corporation is working with some of the leading textile ma-
						chinery manufacturers from all over the world. Over the years,

						Naz Corporation continued to grow, partnering with companies

						that offer leading edge technology and are at the forefront of de-
						velopment. To this day, Naz Corporation has maintained it’s phi-
						losophy of offering its customers the best in terms of machinery,

						auxiliaries, dyes & chemicals and after sales service.”
					</p>
					{/* <Tabs>
						<TabList>
							<Tab>
								<div className='flex items-center justify-between'>
									<p>About US</p>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6'
										/>
									</svg>
								</div>
							</Tab>
							<Tab>
								<div className='flex items-center justify-between'>
									<p>Management</p>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										fill='none'
										viewBox='0 0 24 24'
										strokeWidth={1.5}
										stroke='currentColor'
										className='w-6 h-6'>
										<path
											strokeLinecap='round'
											strokeLinejoin='round'
											d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6'
										/>
									</svg>
								</div>
							</Tab>
						</TabList>

						<TabPanel>
							<div className='panel-content'>
								<p>
									Excellence and innovation…unlimited … through the power of
									"WE"
								</p>
							</div>
						</TabPanel>
						<TabPanel>
							<div className='panel-content'>
								<p>
									To empower our customers, employees, partners and communities
									by providing the finest products, services and practices.
								</p>
							</div>
						</TabPanel>
					</Tabs> */}
				</div>
			</div>
			<Footer />
		</>
	);
};

export default AboutUs;
