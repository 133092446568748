import React from 'react';
import Footer from '../Components/Common/Footer';
import Navbar from '../Components/Common/Navbar';
import PageHead from '../Components/Common/PageHead';

const Management = () => {
	return (
		<>
			<Navbar transparent={false} />
			<PageHead title='Management' />
			<div className='bg-[#F5F5F5] py-8'>
				<div className='container'>
					<div className='grid grid-cols-1 lg:grid-cols-2 text-justify gap-8'>
						<div>
							<div className='flex gap-4 items-center'>
								<img
									src='/img/syed-faruque-ahmed.jpg'
									className='w-2/6'
									alt='Sayed Faruque Ahmed'
								/>
								<div className='text-xl'>
									<p className='text-primary'>CHAIRMAN</p>
									<h4 className='my-3'>SAYED FARUQUE AHMED</h4>
									<p>
										Tel: <a href='tel:88-02-8831188'>88-02-8831188</a>
									</p>
								</div>
							</div>
							<p>
								<br />
								<br />
								Email : farhad.ahmed@aamra.com.bd
								<br />
								<br />
								Syed Farhad Ahmed, a well acknowledged marketing expert and the
								driver of many successful business ventures in Bangladesh, leads
								one of the most successful resources companies in Bangladesh,
								aamra resources Limited, as well as aamra Companies, as its
								Managing Director.
								<br />
								<br />
								Mr. Farhad is a founder shareholder of aamra Companies (formerly
								known as The Texas Group Bangladesh) and started his career
								immediately after university graduation in the group’s garments
								venture in 1985 (set up by his late father Mr. SSF Ahmed, a
								renowned Chartered Accountant of the country) as its Director of
								Operations.
								<br />
								<br />
								Making money and expanding business were never the only driving
								thoughts in his mind. Rather he always aimed to contribute to
								the greater growth of the country and helps creating new job
								opportunities for the people. With this aspiration he
								concentrated on expanding business in new areas and in the
								country’s nascent RMG field to boost the overall growth and
								advancement of the country. Under his able leadership, combined
								with his charismatic personality and motivational capabilities,
								he tends to bring out the very best in all of aamra’s team
								members.
								<br />
								<br />
								Taking the responsibility of overseeing the operations of the
								Group’s 11 companies dealing with ICT (Information &
								Communication Technology), Garments & Textile and Lifestyle and
								HR Development as its Managing Director, he also directly
								oversees aamra companies’ sales and marketing initiatives.
								<br />
								<br />
								Mr. Farhad has been an active participant in numerous business
								and professional organizations including: France-Bangladesh
								Chamber of Commerce & Industry, Bangladesh Garments Manufactures
								and Exporters Association, Bangladesh Computer Shamity and
								Internet Service Providers Association of Bangladesh. Mr. Farhad
								was also an Executive Committee Member & Vice Chairman,
								Marketing Committee of the Bangladesh Cricket Board. He is a
								past president of France-Bangladesh Chamber of Commerce &
								Industry.
							</p>
						</div>
						<div>
							<div className='flex gap-4 items-center'>
								<img
									src='/img/syed-farhad-ahmed.jpg'
									className='w-2/6'
									alt='Sayed Farhad Ahmed'
								/>
								<div className='text-xl'>
									<p className='text-primary'>MANAGING DIRECTOR</p>
									<h4 className='my-3'>SAYED FARHAD AHMED</h4>
									<p>
										Tel: <a href='tel:88-02-8831188'>88-02-8831188</a>
									</p>
								</div>
							</div>
							<p>
								<br />
								<br />
								Email : faruque.ahmed@aamra.com.bd
								<br />
								<br />
								Syed Faruque Ahmed is the current Chairman of aamra resources
								limited as well as aamra Companies (formerly known as The Texas
								Group Bangladesh) and is one of its founder shareholders. aamra
								Companies is a dynamic, futuristic and leading business group in
								the country involved in a wide range of businesses sectors like
								the Garments & Textile, ICT, Lifestyle and HR Development.
								<br />
								<br />
								Mr. Faruque, a post-graduate in Finance, helped starting the
								opening companies of aamra as one of the founder shareholders.
								He was the Director Finance of the Group from 1985.
								<br />
								<br />
								After having played a key role in setting up the Group’s export
								oriented readymade garment business unit from 1985 to 1988, he
								left for USA in 1988 in pursuit of exploring the Group’s garment
								export market. Through his 4 years stay in the U.S., he garnered
								a much wider exposure to the garment industry, in particular the
								US garment industry, as well as gained a rare insight into the
								corporate America.
								<br />
								<br />
								Upon returning home, Mr. Faruque engaged himself back into the
								family business with an added zeal to improve the processes and
								operations; much from what he learnt in the US. He successfully
								implemented computer automation throughout the Group of
								companies for MIS and Accounting that eventually had far
								reaching implications on the rapid growth and success of aamra
								companies. His effort in establishing the vigorous use of IT
								throughout the organization, for example in initiating use of
								network computing in the areas of Accounting, Sales and
								Production, resulted in huge savings of time and resources, as
								well as immensely improved the dynamism and responsiveness of
								the Group.
								<br />
								<br />
								Mr. Faruque takes the lead in the internal development of the
								aamra organizations and plays an active role in continual
								improvements in Human Resources, Organizational Development, MIS
								and Corporate Governance. Over the years he has helped introduce
								a number of important and far-reaching initiatives.
							</p>
						</div>
						<div>
							<div className='flex gap-4 items-center'>
								<img
									src='/img/sohel_ahmed.jpg'
									className='w-2/6'
									alt='Sohel Ahmed'
								/>
								<div className='text-xl'>
									<p className='text-primary'>Chief Operating Officer</p>
									<h4 className='my-3'>Mr. Sohel Ahmed</h4>
									<p>
										Tel: <a href='tel:88-02-9821545'>Tel: +88-02-9821545</a>
									</p>
								</div>
							</div>
							<p>
								<br />
								<br />
								Email : sohel.ahmed@aamra.com.bd
								<br />
								<br />
								Sohel Ahmed is a proven, result-oriented and dynamic leader in
								the country’s Textile and Apparel Machineries and related
								solution industry. Mr. Sohel as Chief Operating Officer of aamra
								resources limited (ARL) has been involved with aamra for the
								last 20 years. His dedication and astute leadership skills has
								helped in making ARL the leader in its trade in Bangladesh.
								<br />
								<br />
								Mr. Sohel joined ARL in 1992 as a Sales Executive trainee and
								through his performance and dedication is now heading the
								Garments and Apparel division of aamra companies. Under his
								leadership ARL has become the most trusted name to the major
								Garment and Textile factories in Bangladesh. Mr. Sohel has been
								working with the pioneers of the Garments industry in Bangladesh
								since the early days and has made significant contributions that
								have enabled increased efficient production for the biggest
								factories in the country. In 2009, for the first time in
								Bangladesh, Sohel introduced efficient factory waste management
								solutions thus enabling factories to lower or eliminate waste
								produced during production process.
								<br />
								<br />
								Under the leadership of Mr. Sohel, ARL has achieved on an
								average 35% growth since 2006. In 2011 ARL became the ‘Best
								Selling Agency by Sclavos SA, Greece during the ITMA
								(International Exhibition of Textile Machinery)’ 2011 Barcelona,
								Spain for its excellent ability of sales for the period 2007 to
								2011. In 2012, ARL held the 3rd position globally as the best
								selling agent of Lectra, the world leader in integrated
								technology solutions based in France.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default Management;
